// ----------------------------------------------------------------
/* eslint-disable array-callback-return */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import R from '../../../../services/app/client-server/request.service.ts'
import Csv from '../../../../img/icon/CsvIcon'
import Excel from '../../../../img/icon/Excel'
import Pdf from '../../../../img/icon/PdfIcon'
import Table from '../../LocalElements/Table/Table'
import PencilIcon from '../../../../img/icon/PencilIcon'
import DeleteIcon from '../../../../img/icon/DeleteIcon'
import ArchiveIcon from '../../../../img/icon/ArchiveIcon'
import PencilDisabled from '../../../../img/icon/PencilDisabled'

import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import { Typography } from '@mui/material'
import theme from '../../../../utils/theme.ts'
import {
  setOpen,
  setTitle,
  setChildren,
  setConfirmAction,
  setBA,
  showBA,
  showBD,
  setBD,
  resetState,
} from '../../../../store/slices/app/comps/modal'
import {
  setOpen as setOpenSnackbar,
  setMessage,
  setType,
} from '../../../../store/slices/app/comps/snackbar'
import { setOpen as setOpenRightContainer } from '../../../../store/slices/app/comps/rightContainer'
import {
  refreshKitVerifications,
  refreshArchives,
} from '../../../../store/slices/app/controlers/updater'
import {
  setArchiveTo,
  resetArchiveFrom,
  resetArchiveTo,
} from '../../../../store/slices/app/views/journals'
import Filter from './filter/index'
import Tooltip from '../../../../ui/tooltip/index.tsx'
import useExcelCsvPdf from '../../../hooks/useExcelCsv'

import { IconFrame } from '../style'

import { archiveTitle, archiveMessage, ModalContent } from '../archiveModal/archiveModal'

const TabKitGraph = () => {
  const userRole = useSelector(state => state.headerReducer.role)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { handleDownload } = useExcelCsvPdf()

  const data = useSelector(state => state.journalReducer.dataKitVerifications)
  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser
  const journalId = useSelector(state => state.journalReducer.journalId)
  const entryId = useSelector(state => state.journalReducer.entryId)

  const isReturned = useSelector(state => state.journalReducer.kitVerifications.isReturned)
  const completenessStatus = useSelector(
    state => state.journalReducer.kitVerifications.completenessStatus,
  )
  const createdByRole = useSelector(state => state.journalReducer.kitVerifications.createdByRole)
  const verificationDateFrom = useSelector(
    state => state.journalReducer.kitVerifications.verificationDateFrom,
  )
  const verificationDateTo = useSelector(
    state => state.journalReducer.kitVerifications.verificationDateTo,
  )
  const createdAtFrom = useSelector(state => state.journalReducer.kitVerifications.createdAtFrom)
  const createdAtTo = useSelector(state => state.journalReducer.kitVerifications.createdAtTo)
  const expirationDateFrom = useSelector(
    state => state.journalReducer.kitVerifications.expirationDateFrom,
  )
  const expirationDateTo = useSelector(
    state => state.journalReducer.kitVerifications.expirationDateTo,
  )

  const archiveDateFromBySettings = useSelector(
    state => state.adminReducer.journalSettings.mainData,
  ).filter(row => row.journal_type_id === journalId)[0]?.create_date
  const archiveDateTo = useSelector(state => state.journalReducer.archiveTo)

  const count = useMemo(() => {
    let filtersCount = 0

    if (isReturned) filtersCount++
    if (completenessStatus) filtersCount++
    if (createdByRole) filtersCount++
    if (createdAtFrom) filtersCount++
    if (createdAtTo) filtersCount++
    if (verificationDateFrom) filtersCount++
    if (verificationDateTo) filtersCount++
    if (expirationDateFrom) filtersCount++
    if (expirationDateTo) filtersCount++
    if (
      !isReturned &&
      !completenessStatus &&
      !createdByRole &&
      !createdAtFrom &&
      !createdAtTo &&
      !verificationDateFrom &&
      !verificationDateTo &&
      !expirationDateFrom &&
      !expirationDateTo
    )
      filtersCount = 0
    return filtersCount
  }, [
    isReturned,
    completenessStatus,
    createdByRole,
    createdAtFrom,
    createdAtTo,
    verificationDateFrom,
    verificationDateTo,
    expirationDateFrom,
    expirationDateTo,
  ])

  const handleAddClick = () => {
    navigate('/metriva/journals/create_line_kit_verifications')
  }

  const handleArchiveClick = async () => {
    const { status, data } = await R.archiveKitVerifications(companyId, {
      date_from: archiveDateFromBySettings,
      date_to: archiveDateTo,
    })

    if (status === 200) {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('success'))
      dispatch(setMessage(data.message))
      dispatch(setOpen(false))
      dispatch(refreshKitVerifications())
      dispatch(refreshArchives())
      dispatch(resetArchiveFrom())
      dispatch(resetArchiveTo())
      dispatch(resetState())
    } else if (status === 409) {
      dispatch(resetState())
      dispatch(
        setTitle(
          <Typography variant='subtitle1' sx={{ color: theme.palette.secondary.dark }}>
            Архивация приостановлена
          </Typography>,
        ),
      )
      dispatch(
        setChildren(
          <div style={{ width: '620px', display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <Typography variant='subtitle1'>
              Внимание! Процесс архивации не может быть завершен из-за отсутствия важных данных в
              некоторых записях документа
            </Typography>
            <Typography variant='subtitle1'>
              Пожалуйста, найдите такие записи с помощью фильтра "Заполнение записи", дождитесь
              появления / внесите недостающие данные и повторите процесс архивации.
            </Typography>
          </div>,
        ),
      )
      dispatch(setBD('Принять'))
      dispatch(showBA(false))
      dispatch(setOpen(true))
    } else if (status === 422) {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('error'))
      dispatch(setMessage(data.message))
      dispatch(setOpen(true))
      dispatch(resetState())
    } else {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('error'))
      dispatch(setMessage(data.message))
      dispatch(setOpen(true))
      dispatch(resetState())
    }
  }

  const handleDelete = async journalId => {
    const { status } = await R.deleteKitVerifications(journalId, companyId)
    if (status === 200) {
      dispatch(refreshKitVerifications())
      dispatch(setType('success'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Запись удалена'))
      dispatch(setOpen(false))
      dispatch(resetState())
    } else {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Произошла ошибка обращения к API'))
      dispatch(resetState())
    }
  }

  const handleDownloadSelectionPdf = async () => {
    const { status, data: responseData } = await R.getArchiveEntriesPdf(
      companyId,
      `?ids[]=${entryId}`,
    )

    if (status === 200) {
      const url = window.URL.createObjectURL(new Blob([responseData]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'archive-selected.pdf')
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    } else {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Ошибка при загрузке документов.'))
    }
  }

  let colData = [
    {
      field: 'number',
      headerName: '№ п/п',
    },
    {
      field: 'name',
      headerName: 'Наименование, тип, заводской №',
    },
    {
      field: 'interval',
      headerName: 'Периодичность поверки, месяцы',
    },
    {
      field: 'date',
      headerName: 'Дата последней поверки',
    },
    {
      field: 'datEexpired',
      headerName: 'Дата истечения срока поверки',
    },
    {
      field: 'isVerification',
      headerName: 'Отметка о новой поверке',
      renderCell: params => {
        return params.value ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <CheckCircleIcon fontSize='small' sx={{ color: '#21E1B3' }} />
          </div>
        ) : (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <CancelIcon fontSize='small' sx={{ color: '#FF5963' }} />
          </div>
        )
      },
    },
    {
      field: 'place',
      headerName: 'Поверяющая организация',
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Действия',
      getActions: params => {
        return [
          <div
            key={params.id}
            style={{
              display: entryId ? 'none' : 'flex',
              flexFlow: 'row',
              justifyContent: 'space-around',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            {params.row.autoGenerated ? (
              <Tooltip title='Редактирование данных о поверке доступно в разделе средства поверки'>
                <div>
                  <PencilDisabled />
                </div>
              </Tooltip>
            ) : (
              <Tooltip title='Редактировать запись'>
                <div style={{ cursor: 'pointer' }} onClick={() => handleEditClick(params.row.id)}>
                  <PencilIcon />
                </div>
              </Tooltip>
            )}

            <Tooltip title='Удалить запись'>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  dispatch(setOpen(true))
                  dispatch(showBA(true))
                  dispatch(setBA('Подтвердить'))
                  dispatch(setTitle('Вы действительно хотите удалить запись?'))
                  dispatch(setChildren('Отменить действие будет уже невозможно.'))
                  dispatch(setConfirmAction(() => handleDelete(params.row.id)))
                }}
              >
                <DeleteIcon />
              </div>
            </Tooltip>
          </div>,
        ]
      },
    },
  ].filter(row => {
    if (!!!entryId) {
      return row
    } else {
      if (row.field !== 'actions') {
        return row
      }
    }
  })

  const handleEditClick = id => {
    navigate(`/metriva/journals/${id}/edit_kit_verifications`)
  }

  const modalContent = (
    <ModalContent
      archiveDateFromBySettings={archiveDateFromBySettings}
      archiveDateTo={archiveDateTo}
      setArchiveTo={setArchiveTo}
      dispatch={dispatch}
    />
  )

  const StaticControls = (
    <IconFrame>
      {count > 0 ? (
        <Tooltip title='Для скачивания PDF отключите фильтры'>
          <div style={{ cursor: 'default', opacity: '40%', display: 'flex', alignItems: 'center' }}>
            <Pdf />
          </div>
        </Tooltip>
      ) : (
        <Tooltip title='Скачать в формате PDF'>
          <div
            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            onClick={() => {
              !entryId && handleDownload('pdf')
              !!entryId && handleDownloadSelectionPdf()
            }}
          >
            <Pdf />
          </div>
        </Tooltip>
      )}
      {!entryId && (
        <Tooltip title='Скачать документ в Csv'>
          <div
            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            onClick={() => handleDownload('csv')}
          >
            <Csv />
          </div>
        </Tooltip>
      )}
      {!entryId && (
        <Tooltip title='Скачать документ в Excel'>
          <div
            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            onClick={() => handleDownload('excel')}
          >
            <Excel />
          </div>
        </Tooltip>
      )}
      {!entryId && (
        <React.Fragment>
          {count > 0 ? (
            <Tooltip title='Для архивации документа отключите фильтры'>
              <div
                style={{ cursor: 'default', opacity: '40%', display: 'flex', alignItems: 'center' }}
              >
                <ArchiveIcon />
              </div>
            </Tooltip>
          ) : (
            <Tooltip title='Архивировать документ'>
              <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                <ArchiveIcon
                  onClick={() => {
                    dispatch(setTitle('Архивация документа'))
                    dispatch(setChildren(modalContent))
                    dispatch(setBA('Архивировать'))
                    dispatch(setBD('Отменить'))
                    dispatch(showBD(true))
                    dispatch(setConfirmAction(handleArchiveClick))
                    dispatch(setOpen(true))
                  }}
                />
              </div>
            </Tooltip>
          )}
        </React.Fragment>
      )}
    </IconFrame>
  )

  return (
    <main>
      <Filter />
      <Table
        colData={colData}
        rowData={data.map(item => ({
          id: item.id,
          number: item?.number ?? '-',
          name: item?.name ?? '-',
          interval: item?.interval ?? '-',
          date: item?.date ?? '-',
          datEexpired: item?.date_expired ?? '-',
          isVerification: item?.is_has_new_verification ?? '-',
          place: item?.place ?? '-',
          autoGenerated: item?.is_autogenerated ?? '-',
        }))}
        hasCheckboxSelection={false}
        noRowsText={'Записи отсутствуют'}
        showToolbar={true}
        noCreating={!entryId ? false : true}
        actions={{
          static: StaticControls,
          dinamic: null,
        }}
        filtersVariant={'server'}
        addButtonCallback={handleAddClick}
        setIsDrawerOpenServer={() => {
          dispatch(setOpenRightContainer(true))
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
      />
    </main>
  )
}

export default TabKitGraph
