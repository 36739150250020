import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import css from '../../style.css'

import { IModelProtocolMi1592MobileShow } from '../../../../../../types'

const { Container, Mobile } = css

const ExternalInspection: React.FC = (): ReactElement => {
  const viewData: IModelProtocolMi1592MobileShow = useSelector(
    (state: any) => state.showProtocolReducer.data,
  )

  return (
    <React.Fragment>
      <Container>
        <Mobile.Paper>
          <h3>Внешний осмотр</h3>
          <Mobile.ContentRow>
            <span style={{ width: '80%' }}>
              Соответствует пункту 2.7.1 Методики поверки МИ 1592-2015
            </span>
            {viewData.mechanical_accepted === true ? (
              <span
                style={{
                  backgroundColor: '#E5F3FC',
                  color: '#0589E7',
                  padding: '10px 14px',
                  borderRadius: '10px',
                }}
              >
                Да
              </span>
            ) : (
              <span
                style={{
                  backgroundColor: '#FFE7E8',
                  color: '#FF0000',
                  padding: '10px 14px',
                  borderRadius: '10px',
                }}
              >
                Нет
              </span>
            )}
          </Mobile.ContentRow>
        </Mobile.Paper>
      </Container>
      <Container>
        <Mobile.Paper>
          <h3>Опробование</h3>
          <Mobile.ContentRow>
            <span style={{ width: '80%' }}>
              Соответствует пункту 2.7.1 Методики поверки МИ 1592-2015
            </span>
            {viewData.tightness_accepted === true ? (
              <span
                style={{
                  backgroundColor: '#E5F3FC',
                  color: '#0589E7',
                  padding: '10px 14px',
                  borderRadius: '10px',
                }}
              >
                Да
              </span>
            ) : (
              <span
                style={{
                  backgroundColor: '#FFE7E8',
                  color: '#FF0000',
                  padding: '10px 14px',
                  borderRadius: '10px',
                }}
              >
                Нет
              </span>
            )}
          </Mobile.ContentRow>
        </Mobile.Paper>
      </Container>
    </React.Fragment>
  )
}

export default ExternalInspection
