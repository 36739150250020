// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Collapse } from '@mui/material'

import R from '../../../services/app/client-server/request.service.ts'

import VerificationDate from '../../components/Methodologies/views/ProtocolVerificationDate/VerificationDate.jsx'
import Cases from '../../components/Methodologies/views/ProtocolCases/ProtocolCases'
import TypeMethod from '../../components/Methodologies/views/TypeMethod/TypeMethod'
import WaterMi1592Copy from '../../components/Methodologies/water/Mi-1592-2015/desktop/Copy'
import BackButton from '../../../ui/back-button/index.tsx'

import {
  changeCase,
  resetCase,
  resetMeasurementId,
  resetCompanyId,
  resetMethodId,
  setDateVerification,
} from '../../../store/slices/app/views/protocols'

import css from '../../components/Methodologies/styles.css'
const { BoxContainer } = css

const Copy = () => {
  const { protocol_id } = useParams()
  const dispatch = useDispatch()

  const caseId = useSelector(state => state.protocolReducer.case.caseId)
  const pointId = useSelector(state => state.protocolReducer.case.pointId)
  const dateVerification = useSelector(state => state.protocolReducer.dateVerification)

  const [data, setData] = useState([])
  const [availableDates, setAvailableDates] = useState([])

  useEffect(() => {
    ;(async () => {
      const { data } = await R.getProtocol(protocol_id)
      setData(data?.data)

      if (data.data?.originalCase) {
        setAvailableDates(data?.data?.originalCase.available_dates || [])
      }

      dispatch(setDateVerification(data?.data?.verification_date))

      dispatch(
        changeCase({
          value: 'caseId',
          label: data?.data?.originalCase?.id,
        }),
      )

      dispatch(
        changeCase({
          value: 'method',
          label: data?.data?.originalCase?.verification_methods,
        }),
      )

      dispatch(
        changeCase({
          value: 'verificationType',
          label: data?.data?.originalCase?.verification_types,
        }),
      )

      dispatch(
        changeCase({
          value: 'pointId',
          label: data?.data?.point?.id,
        }),
      )

      const initialPoint = data?.data?.originalCase?.points.map(item => ({
        id: item.id,
        name: item.name,
        additional: item.additional,
      }))

      dispatch(
        changeCase({
          value: 'pointMethod',
          label: initialPoint,
        }),
      )
    })()
  }, [protocol_id, dispatch])

  const handleDateChange = date => {
    if (date) {
      dispatch(setDateVerification(date.format('YYYY-MM-DD HH:mm:ss')))
    }
  }

  useEffect(() => {
    return () => {
      dispatch(resetCase())
      dispatch(resetMeasurementId())
      dispatch(resetCompanyId())
      dispatch(resetMethodId())
      dispatch(setDateVerification(''))
    }
  }, [])

  return (
    <main className={'main'}>
      <BackButton to='/metriva/protocols' label='Назад к списку протоколов' />

      <div
        className='width32Percent'
        style={{ display: 'flex', flexFlow: 'column', gap: '9px', width: '100%' }}
      >
        <VerificationDate
          dateVerification={dateVerification}
          handleDateChange={handleDateChange}
          availableDates={availableDates}
          isDisabled={false}
        />
      </div>

      <div style={{ margin: '14px 0' }}>
        <Cases data={data} />
      </div>

      <Collapse in={!!caseId}>
        <BoxContainer margin={'0'}>
          <TypeMethod />
        </BoxContainer>
      </Collapse>

      {(pointId === 1 || pointId === 3) && <WaterMi1592Copy />}
    </main>
  )
}

export default Copy
