import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import css from '../../style.css'

import { IModelProtocolMi1592MobileShow } from '../../../../../../types'

const { Container, Mobile } = css

const ComplianceWithVerification: React.FC = (): ReactElement => {
  const viewData: IModelProtocolMi1592MobileShow = useSelector(
    (state: any) => state.showProtocolReducer.data,
  )

  return (
    <React.Fragment>
      <Container>
        <Mobile.Paper>
          <Mobile.ContentRow>
            <h3>На начало поверки</h3>
          </Mobile.ContentRow>
          <Mobile.ContentRow>
            <span style={{ width: '80%' }}>Температура окр. среды</span>
            <span style={{ width: '20%', color: '#858585' }}>
              {viewData.protocolCondition[0].temperature_environment} С°
            </span>
          </Mobile.ContentRow>
          <Mobile.ContentRow>
            <span style={{ width: '80%' }}>Относительная влажность</span>
            <span style={{ width: '20%', color: '#858585' }}>
              {viewData.protocolCondition[0].relative_humidity} %
            </span>
          </Mobile.ContentRow>
          <Mobile.ContentRow>
            <span style={{ width: '80%' }}>Атмосферное давление</span>
            <span style={{ width: '20%', color: '#858585' }}>
              {viewData.protocolCondition[0].atmospheric_pressure} кПа
            </span>
          </Mobile.ContentRow>
          <Mobile.ContentRow>
            <span style={{ width: '80%' }}>Температура воды</span>
            <span style={{ width: '20%', color: '#858585' }}>
              {viewData.protocolCondition[0].water_temp} С°
            </span>
          </Mobile.ContentRow>
        </Mobile.Paper>
      </Container>
      <Container>
        <Mobile.Paper>
          <Mobile.ContentRow>
            <h3>По окончанию поверки</h3>
          </Mobile.ContentRow>
          <Mobile.ContentRow>
            <span style={{ width: '80%' }}>Температура окр. среды</span>
            <span style={{ width: '20%', color: '#858585' }}>
              {viewData.protocolCondition[1].temperature_environment} С°
            </span>
          </Mobile.ContentRow>
          <Mobile.ContentRow>
            <span style={{ width: '80%' }}>Относительная влажность</span>
            <span style={{ width: '20%', color: '#858585' }}>
              {viewData.protocolCondition[1].relative_humidity} %
            </span>
          </Mobile.ContentRow>
          <Mobile.ContentRow>
            <span style={{ width: '80%' }}>Атмосферное давление</span>
            <span style={{ width: '20%', color: '#858585' }}>
              {viewData.protocolCondition[1].atmospheric_pressure} кПа
            </span>
          </Mobile.ContentRow>
          <Mobile.ContentRow>
            <span style={{ width: '80%' }}>Температура воды</span>
            <span style={{ width: '20%', color: '#858585' }}>
              {viewData.protocolCondition[1].water_temp} С°
            </span>
          </Mobile.ContentRow>
        </Mobile.Paper>
      </Container>
    </React.Fragment>
  )
}

export default ComplianceWithVerification
