// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Collapse, Typography } from '@mui/material'

import Method from '../../components/Methodologies/views/Method/Method'
import TypeMethod from '../../components/Methodologies/views/TypeMethod/TypeMethod'

import WaterMi15922015 from '../../components/Methodologies/water/Mi-1592-2015/desktop/Add.jsx'
import WaterMi15922015Mobile from '../../components/Methodologies/water/Mi-1592-2015/mobile/Add.jsx'

import GasGost8324 from '../../components/Methodologies/gas/Gost8324-2002/AddProtocol'

import Gost81012 from '../../components/Methodologies/water/Gost-8-1012-2022/desktop/Add.tsx'

import useWindowDimensions from '../../hooks/useWindowDimensions.js'

import {
  resetCase,
  resetMeasurementId,
  resetCompanyId,
  resetMethodId,
  setDateVerification,
} from '../../../store/slices/app/views/protocols'
import { setName } from '../../../store/slices/app/controlers/pageName'

import css from '../../components/Methodologies/styles.css'
const { BoxContainer } = css

const NewProtocol = () => {
  const dispatch = useDispatch()

  const { width } = useWindowDimensions()

  const caseId = useSelector(state => state.protocolReducer.case.caseId)
  const pointId = useSelector(state => state.protocolReducer.case.pointId)
  const step = useSelector(state => state.protocolReducer.stepNumber)

  useEffect(() => {
    dispatch(setName('Новый протокол'))
  }, [])
  useEffect(() => {
    return () => {
      dispatch(resetCase())
      dispatch(resetMeasurementId())
      dispatch(resetCompanyId())
      dispatch(resetMethodId())
      dispatch(setDateVerification(''))
    }
  }, [])

  return (
    <main className='main'>
      {step === 0 && width > 1050 && <Method />}
      {step === 0 && width > 1050 && (
        <Collapse in={!!caseId}>
          <BoxContainer margin={'12px 0px 0px'}>
            <TypeMethod />
          </BoxContainer>
        </Collapse>
      )}

      {pointId === null && width > 1050 && (
        <React.Fragment>
          <div className='page_null'>
            <Typography variant='body1' style={{ textAlign: 'center' }}>
              Выберите базовые параметры, чтобы продолжить создание протокола
            </Typography>
          </div>
        </React.Fragment>
      )}

      {(pointId === 1 || pointId === 3) && width > 1050 && (
        <React.Fragment>
          <WaterMi15922015 />
        </React.Fragment>
      )}

      {true /* pointId === 1 || pointId === 3 */ && width <= 1050 && (
        <React.Fragment>
          <WaterMi15922015Mobile />
        </React.Fragment>
      )}

      {pointId === 2 && <GasGost8324 />}

      {(pointId === 4 || pointId === 5) && <Gost81012 />}
    </main>
  )
}

export default NewProtocol
