import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import css from '../../style.css'

import { IModelProtocolMi1592MobileShow } from '../../../../../../types'

const { Container, Mobile } = css

const CustomerInfo: React.FC = (): ReactElement => {
  const viewData: IModelProtocolMi1592MobileShow = useSelector(
    (state: any) => state.showProtocolReducer.data,
  )

  return (
    <React.Fragment>
      <Container>
        <Mobile.Paper>
          <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
            <span>Владелец СИ</span>
          </Mobile.ContentRow>
          <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
            {/* <span style={{ color: '#858585' }}>
              {`${viewData.originalCase.arshinMaster.name} ${viewData.originalCase.arshinMaster.patr} ${viewData.originalCase.arshinMaster.surname}`}
            </span> */}
            <span style={{ color: '#858585' }}>{viewData.customer_arshin_name}</span>
          </Mobile.ContentRow>
          <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
            <span>Адрес</span>
          </Mobile.ContentRow>
          <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
            <span style={{ color: '#858585' }}>{viewData.customer_address}</span>
          </Mobile.ContentRow>
          <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
            <span>ФИО</span>
          </Mobile.ContentRow>
          <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
            <span style={{ color: '#858585' }}>{'Не указаны'}</span>
          </Mobile.ContentRow>
          <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
            <span>Телефон заказчика</span>
          </Mobile.ContentRow>
          <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
            <span style={{ color: '#858585' }}>{'Не указан'}</span>
          </Mobile.ContentRow>
          <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
            <span>Номер договора</span>
          </Mobile.ContentRow>
          <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
            <span style={{ color: '#858585' }}>{'Не указан'}</span>
          </Mobile.ContentRow>
        </Mobile.Paper>
      </Container>
    </React.Fragment>
  )
}

export default CustomerInfo
