// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Button from '@mui/material/Button'

import FilterMetersBase from '../../components/Meters/FilterMetersBase'
import TableWater from '../../components/Meters/MeterWater/Tab'
import TableGas from '../../components/Meters/MeterGas/Tab'
import Selector from '../../components/LocalElements/Selector/reactSelect/Selector'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'

import useWindowDimensions from '../../hooks/useWindowDimensions'
import RightSideContainer from '../../components/LocalElements/RightSideContainer/RightSideContainer'

import {
  changeFilterParams,
  changeFilterMethods,
  changeFilterTitles,
  changeFilterCustomers,
} from '../../../store/slices/app/views/meters'

const Meters = () => {
  const dispatch = useDispatch()
  const mainRef = useRef()
  const { width } = useWindowDimensions()

  const measurement_id = useSelector(state => state.meterReducer.measurement_id)
  const customersDataForFilter = useSelector(state => state.meterReducer.filterParams.customers)
  const titlesDataForFilter = useSelector(state => state.meterReducer.filterParams.titles)
  const methodsDataForFilter = useSelector(state => state.meterReducer.filterParams.methods)

  const methods = useSelector(state => state.meterReducer.filterParams.selectedMethods)
  const customers = useSelector(state => state.meterReducer.filterParams.selectedCustomers)
  const titles = useSelector(state => state.meterReducer.filterParams.selectedTitles)
  const linkSI = useSelector(state => state.meterReducer.filterParams.isLinkSI)
  const pdf = useSelector(state => state.meterReducer.filterParams.isPDF)

  const ActiveFilters = () => (
    <RightSideContainer blockTitle={'Фильтры'}>
      <div className='right-side-container'>
        <div className='right-side-container-block'>
          <span
            style={{
              display: 'block',
              width: '100%',
              fontSize: '15px',
              fontWeight: 600,
            }}
          >
            Завод-изготовитель
          </span>
          <div style={{ width: '100%' }}>
            <Selector
              placeholder={'Выберите один вариант'}
              options={customersDataForFilter}
              onChange={option => {
                dispatch(changeFilterCustomers(option.label))
              }}
              value={
                customers ? customersDataForFilter.find(option => option.label === customers) : null
              }
              isClearable={true}
              isSearchable={true}
              noOptionsMessage={() => 'Нет доступных вариантов'}
            />
          </div>
        </div>
        <div className='right-side-container-block'>
          <span
            style={{
              display: 'block',
              width: '100%',
              fontSize: '15px',
              fontWeight: 600,
            }}
          >
            Наименование и тип СИ
          </span>
          <div style={{ width: '100%' }}>
            <Selector
              placeholder={'Начните вводить название'}
              options={titlesDataForFilter}
              onChange={option => {
                dispatch(changeFilterTitles(option.label))
              }}
              value={titlesDataForFilter.find(option => option.label === titles) || null}
              isClearable={true}
              isSearchable={true}
              noOptionsMessage={() => 'Нет доступных вариантов'}
            />
          </div>
        </div>
        <div className='right-side-container-block'>
          <span
            style={{
              display: 'block',
              width: '100%',
              fontSize: '15px',
              fontWeight: 600,
            }}
          >
            Методика поверки
          </span>
          <div style={{ width: '100%' }}>
            <Selector
              placeholder={'Выберите одну или несколько'}
              options={methodsDataForFilter}
              onChange={options => {
                dispatch(changeFilterMethods(options.map(option => option.label)))
              }}
              isMulti
              value={methods.map(method => ({ value: method, label: method }))}
              isClearable={true}
              isSearchable={true}
              noOptionsMessage={() => 'Нет доступных вариантов'}
            />
          </div>
        </div>
        <div className='right-side-container-block'>
          <span
            style={{
              display: 'block',
              width: '100%',
              fontSize: '15px',
              fontWeight: 600,
            }}
          >
            Ссылка на ФГИС Аршин
          </span>
          <div style={{ width: '100%' }}>
            {!!!false && (
              <RadioGroup
                aria-labelledby='demo-controlled-radio-buttons-group'
                name='controlled-radio-buttons-group'
                value={linkSI || null}
                onChange={event => {
                  false && console.log(event.target.value)

                  dispatch(
                    changeFilterParams({
                      value: 'isLinkSI',
                      label: event.target.value,
                    }),
                  )
                }}
                style={{
                  flexDirection: 'row',
                  fontFamily: "'Wix Madefor Display', sans-serif",
                  color: '#123532',
                  fontSize: '16px',
                  fontWeight: '400',
                  marginTop: '0px',
                  paddingLeft: '5px',
                }}
              >
                <FormControlLabel
                  value={0}
                  control={
                    <Radio
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: '22px !important',
                        },
                      }}
                    />
                  }
                  label='Нет ссылки'
                  sx={{
                    '& .MuiTypography-root': {
                      fontFamily: "'Wix Madefor Display', sans-serif !important",
                      fontSize: '15px !important',
                      fontWeight: 400,
                      color: '#132532',
                    },
                  }}
                />
                <FormControlLabel
                  value={1}
                  control={
                    <Radio
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: '22px !important',
                        },
                      }}
                    />
                  }
                  label='Есть ссылка'
                  sx={{
                    '& .MuiTypography-root': {
                      fontFamily: "'Wix Madefor Display', sans-serif !important",
                      fontSize: '15px !important',
                      fontWeight: 400,
                      color: '#132532',
                    },
                  }}
                />
              </RadioGroup>
            )}
          </div>
        </div>
        <div className='right-side-container-block'>
          <span
            style={{
              display: 'block',
              width: '100%',
              fontSize: '15px',
              fontWeight: 600,
            }}
          >
            PDF с описанием типа
          </span>
          <div style={{ width: '100%' }}>
            {!!!false && (
              <RadioGroup
                aria-labelledby='demo-controlled-radio-buttons-group'
                name='controlled-radio-buttons-group'
                value={pdf || null}
                onChange={event => {
                  false && console.log(event.target.value)

                  dispatch(
                    changeFilterParams({
                      value: 'isPDF',
                      label: event.target.value,
                    }),
                  )
                }}
                style={{
                  flexDirection: 'row',
                  fontFamily: "'Wix Madefor Display', sans-serif",
                  color: '#123532',
                  fontSize: '16px',
                  fontWeight: '400',
                  marginTop: '0px',
                  paddingLeft: '5px',
                }}
              >
                <FormControlLabel
                  value={0}
                  control={
                    <Radio
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: '22px !important',
                        },
                      }}
                    />
                  }
                  label='PDF отсутствует'
                  sx={{
                    '& .MuiTypography-root': {
                      fontFamily: "'Wix Madefor Display', sans-serif !important",
                      fontSize: '15px !important',
                      fontWeight: 400,
                      color: '#132532',
                    },
                  }}
                />
                <FormControlLabel
                  value={1}
                  control={
                    <Radio
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: '22px !important',
                        },
                      }}
                    />
                  }
                  label='PDF присутствует'
                  sx={{
                    '& .MuiTypography-root': {
                      fontFamily: "'Wix Madefor Display', sans-serif !important",
                      fontSize: '15px !important',
                      fontWeight: 400,
                      color: '#132532',
                    },
                  }}
                />
              </RadioGroup>
            )}
          </div>
        </div>
        <div style={{ width: '100%' }}>
          <Button
            variant='outlined'
            onClick={() => {
              dispatch(changeFilterCustomers(''))
              dispatch(changeFilterTitles(''))
              dispatch(changeFilterMethods([]))
              dispatch(
                changeFilterParams({
                  value: 'isLinkSI',
                  label: null,
                }),
              )
              dispatch(
                changeFilterParams({
                  value: 'isPDF',
                  label: null,
                }),
              )
            }}
            style={{
              width: '100%',
              textTransform: 'none',
              fontSize: '15px',
              fontFamily: '"Wix Madefor Display", sans-serif',
              padding: '11px 20px 11px',
              borderRadius: '12px',
            }}
          >
            Сбросить все значения
          </Button>
        </div>
      </div>
    </RightSideContainer>
  )

  return (
    <main className='main'>
      <ActiveFilters />
      <div className='header_box'>
        <div
          className='flexContainerWithGap'
          style={{
            flexWrap: 'wrap',
            width: '100%',
            gap: '12px',
            position: 'relative',
            ...(width <= 1050 && {
              marginTop: '0px',
            }),
          }}
        >
          <div className='selector' style={{ width: '100%', position: 'relative' }}>
            <div
              style={{
                width: width > 1050 ? '100%' : '100%',
                marginBottom: '0px',
              }}
            >
              <FilterMetersBase />
            </div>
          </div>
        </div>
      </div>

      {measurement_id === 1 && <TableWater />}
      {measurement_id === 5 && <TableGas onDelete={() => {}} />}
    </main>
  )
}

export default Meters
