// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React from 'react'
import { Checkbox, Typography } from '@mui/material'
import { Wrapper, CheckBoxFrame } from '../../styles.ts'

import useWindowDimensions from '../../../../hooks/useWindowDimensions.js'

const VerificationMark = props => {
  const { valuePassport, onChangePassport, valueSi, onChangeSi } = props

  const { width } = useWindowDimensions()

  return (
    <Wrapper style={width > 1050 ? {} : { padding: '0px', borderRadius: '8px', border: 'none' }}>
      <Typography variant='h2'>Знак поверки</Typography>
      <div>
        <CheckBoxFrame>
          <Checkbox checked={valuePassport} onChange={onChangePassport} id='valuePassport' />
          <label
            for='valuePassport'
            style={{ fontSize: '15px', lineHeight: '24px', color: '#4f5960' }}
          >
            Знак поверки в паспорте
          </label>
        </CheckBoxFrame>
        <CheckBoxFrame>
          <Checkbox checked={valueSi} onChange={onChangeSi} id='valueSi' />
          <label for='valueSi' style={{ fontSize: '15px', lineHeight: '24px', color: '#4f5960' }}>
            Знак поверки на СИ
          </label>
        </CheckBoxFrame>
      </div>
    </Wrapper>
  )
}

export default VerificationMark
