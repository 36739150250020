import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Typography } from '@mui/material'
import Table from '../../LocalElements/Table/Table'
import Button from '../../../../ui/button/index.tsx'
import PencilIcon from '../../../../img/icon/PencilIcon'
import ArchiveIcon from '../../../../img/icon/ArchiveIcon'
import PdfIcon from '../../../../img/icon/PdfIcon.jsx'
import InfoCircle from '../../../../img/icon/InfoCircle'
import R from '../../../../services/app/client-server/request.service.ts'
import {
  refreshKitPassport,
  refreshArchives,
} from '../../../../store/slices/app/controlers/updater'
import { setSelectedKitPassport } from '../../../../store/slices/app/views/journals'
import { setOpen as setOpenRightContainer } from '../../../../store/slices/app/comps/rightContainer'
import {
  setOpen,
  setTitle,
  setChildren,
  setConfirmAction,
  setBA,
  showBA,
  resetState,
} from '../../../../store/slices/app/comps/modal'

import {
  setOpen as setOpenSnackbar,
  setMessage,
  setType,
} from '../../../../store/slices/app/comps/snackbar'

import Filter from './filter/index'
import Tooltip from '../../../../ui/tooltip/index.tsx'
import theme from '../../../../utils/theme.ts'

const TableKitPassports = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const data = useSelector(state => state.journalReducer.dataKitPassports)
  const selectedKitPassport = useSelector(state => state.journalReducer.selectedKitPassport)
  const userRole = useSelector(state => state.headerReducer.role)
  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser
  const entryId = useSelector(state => state.journalReducer.entryId)

  const rowData = data.map(item => ({
    id: item.id,
    formedDate: item?.formed_date ?? '-',
    accountingNumber: item?.accounting_number ?? '-',
    name: item?.name ?? '-',
    existingStatus: item?.existing_status ?? '-',
    inventoryNumber: item?.inventory_number ?? '-',
    registerNumber: item?.register_number ?? '-',
    manufacturerNumber: item?.manufacturer_number ?? '-',
    responsiblePassportName: item?.responsible_passport_name ?? '-',
    kitId: item?.kit_id,
  }))

  const handleArchiveClick = async journalId => {
    const { status, data } = await R.archiveKitPassports(journalId, companyId)

    if (status === 200) {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('success'))
      dispatch(setMessage(data.message))
      dispatch(setOpen(false))
      dispatch(refreshKitPassport())
      dispatch(refreshArchives())
      dispatch(resetState())
    } else {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('error'))
      dispatch(setMessage(data.message))
      dispatch(setOpen(true))
      dispatch(resetState())
    }
  }

  const handleLinkClick = id => {
    navigate(`/metriva/etalon/${id}/edit`)
  }

  const archiveMessage = (
    <section
      style={{
        display: 'flex',
        flexFlow: 'column',
        width: '620px',
        gap: 8,
      }}
    >
      <Typography variant='subtitle2'>
        Вы действительно хотите перенести выбранную карточку в архив?
      </Typography>
      <Typography variant='subtitle2'>
        Архивная версия карточки будет доступна только в режиме просмотра.
      </Typography>
    </section>
  )

  let colData = [
    {
      field: 'formedDate',
      headerName: 'Дата составления',
    },
    {
      field: 'accountingNumber',
      headerName: 'Учетный №',
    },
    {
      field: 'name',
      headerName: 'Наименование СП, тип',
    },
    {
      field: 'existingStatus',
      headerName: 'СП добавлен в систему',
      renderCell: params => {
        return params.value ? (
          <Typography
            variant='subtitle2'
            color={theme.palette.text.secondary}
            sx={{ cursor: 'pointer', borderBottom: `1px solid ${theme.palette.text.secondary}` }}
            onClick={event => {
              event.stopPropagation()
              handleLinkClick(params.row.kitId)
            }}
          >
            Да
          </Typography>
        ) : (
          <Typography variant='subtitle2' color={'#899298'}>
            Нет
          </Typography>
        )
      },
    },
    {
      field: 'inventoryNumber',
      headerName: 'Инвентариный №',
    },
    {
      field: 'registerNumber',
      headerName: 'Регистрационный №',
    },
    {
      field: 'manufacturerNumber',
      headerName: 'Заводской №',
    },
    {
      field: 'responsiblePassportName',
      headerName: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          ФИО ответственного за ведение карточки
          <Tooltip
            title={
              'Дата составления карточки равна дате поступления СП на учет. См. данные о конкретном СП в разделе "Средства поверки", блок "Сведения по учету".'
            }
            style={{ marginLeft: '7px' }}
          >
            <InfoCircle />
          </Tooltip>
        </div>
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Действия',
      getActions: params => {
        return [
          <div
            key={params.id}
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'space-around',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            {params.row.existingStatus ? (
              <Tooltip title='Редактирование данных доступно только в разделе "Средства поверки"'>
                <div>
                  <PencilIcon color='#BDC1C4' />
                </div>
              </Tooltip>
            ) : (
              <Tooltip title='Редактировать запись'>
                <div style={{ cursor: 'pointer' }} onClick={() => handleEditClick(params.row.id)}>
                  <PencilIcon />
                </div>
              </Tooltip>
            )}

            {params.row.existingStatus ? (
              <Tooltip title='Архивирование доступно только после удаления связанного СП из системы'>
                <div>
                  <ArchiveIcon color='#BDC1C4' width={21} height={21} />
                </div>
              </Tooltip>
            ) : (
              <Tooltip title='Архивировать регистрационную карточку'>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    dispatch(setOpen(true))
                    dispatch(showBA(true))
                    dispatch(setBA('Архивировать'))
                    dispatch(setTitle('Архивация регистрационной карточки (паспорта)'))
                    dispatch(setChildren(archiveMessage))
                    dispatch(setConfirmAction(() => handleArchiveClick(params.row.id)))
                  }}
                >
                  <ArchiveIcon color='#899298' width={21} height={21} />
                </div>
              </Tooltip>
            )}
          </div>,
        ]
      },
    },
  ].filter(row => {
    if (!entryId) {
      return row
    } else {
      if (row.field !== 'actions') {
        return row
      }
    }
  })

  const handleEditClick = id => {
    navigate(`/metriva/journals/${id}/edit_journals_kit_passports`)
  }

  const handlePdf = async () => {
    const dataToSend = {
      ids: selectedKitPassport,
    }

    try {
      const { status, data: fileData } = await R.KitPassportsPdf(companyId, dataToSend)

      if (status === 200) {
        let blob
        let fileName
        const currentDate = new Date().toLocaleDateString('ru-RU').replace(/\./g, '-')

        if (selectedKitPassport.length === 1) {
          blob = new Blob([fileData], { type: 'application/pdf' })
          const num = rowData.find(item => item.id === selectedKitPassport[0])?.accountingNumber
          fileName = `Pasport_sp_${num}.pdf`
        } else {
          blob = new Blob([fileData], { type: 'application/zip' })
          fileName = `Pasporta_sp_${currentDate}.zip`
        }

        const url = URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.href = url
        link.download = fileName
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        URL.revokeObjectURL(url)

        dispatch(setMessage('PDF успешно скачан(ы).'))
        dispatch(setType('success'))
        dispatch(setOpenSnackbar(true))
      } else {
        dispatch(setType('error'))
        dispatch(setOpenSnackbar(true))
        dispatch(setMessage(fileData.message))
      }
    } catch (error) {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Ошибка при обращении к API.'))
    }
  }

  const DinamicActions = (
    <div
      style={{
        display: 'flex',
        flexFlow: 'row',
        alignItems: 'center',
        height: '100%',
        gap: '10px',
      }}
    >
      <Button
        color='text'
        startIcon={<PdfIcon />}
        endIcon={undefined}
        label='Скачать регистрационную карточку (паспорт) выбранных СП'
        onClick={handlePdf}
        styles={{
          padding: 0,
          color: 'inherit',
          minWidth: '50px',
          paddingLeft: '16px',
          paddingRight: '14px',
          marginLeft: '-10px',
          fontSize: '.88rem',
        }}
      />
    </div>
  )

  return (
    <main>
      <Filter />
      <Table
        colData={colData}
        rowData={rowData}
        hasCheckboxSelection={true}
        rowSelectionCallback={array => {
          dispatch(setSelectedKitPassport(array))
        }}
        noRowsText={'Записи отсутствуют'}
        showToolbar={true}
        noCreating={true}
        actions={{
          static: null,
          dinamic: selectedKitPassport && selectedKitPassport.length > 0 ? DinamicActions : null,
        }}
        filtersVariant={'server'}
        addButtonCallback={() => {}}
        setIsDrawerOpenServer={() => {
          dispatch(setOpenRightContainer(true))
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
      />
    </main>
  )
}

export default TableKitPassports
