import React, { ReactElement } from 'react'

const Images: React.FC = (): ReactElement => {
  return (
    <React.Fragment>
      <span style={{ width: '100%', textAlign: 'center', margin: '30px 0px' }}>
        блок в разработке
      </span>
    </React.Fragment>
  )
}

export default Images
