import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import css from '../../style.css'

import { IModelProtocolMi1592MobileShow } from '../../../../../../types'

const { Container, Mobile } = css

const MetrologicalChars: React.FC = (): ReactElement => {
  const viewData: IModelProtocolMi1592MobileShow = useSelector(
    (state: any) => state.showProtocolReducer.data,
  )

  return (
    <React.Fragment>
      <Container>
        <Mobile.Paper>
          <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
            <span style={{ width: '80%' }}>Диаметр условного прохода</span>
            <span style={{ color: '#858585', width: '20%' }}>{`${viewData.details.diameter}`}</span>
          </Mobile.ContentRow>
          <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
            <span style={{ width: '80%' }}>Расположение прибора</span>
            <span
              style={{ color: '#858585', width: '20%' }}
            >{`${viewData.details.orientation}`}</span>
          </Mobile.ContentRow>
          <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
            <span style={{ width: '80%' }}>Измеряемая вода</span>
            <span
              style={{ color: '#858585', width: '20%' }}
            >{`${viewData.details.meter_type}`}</span>
          </Mobile.ContentRow>
          <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
            <span style={{ width: '80%' }}>Qmin</span>
            <span style={{ color: '#858585', width: '20%' }}>{`${viewData.details.q_min}`}</span>
          </Mobile.ContentRow>
          <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
            <span style={{ width: '80%' }}>Qt</span>
            <span style={{ color: '#858585', width: '20%' }}>{`${viewData.details.q_t}`}</span>
          </Mobile.ContentRow>
          <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
            <span style={{ width: '80%' }}>Qmax</span>
            <span style={{ color: '#858585', width: '20%' }}>{`${viewData.details.q_max}`}</span>
          </Mobile.ContentRow>
          <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
            <span style={{ width: '80%' }}>Погрешность от Qmin до Qt</span>
            <span
              style={{ color: '#858585', width: '20%' }}
            >{`${viewData.details.q_min_limit}%`}</span>
          </Mobile.ContentRow>
          <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
            <span style={{ width: '80%' }}>Погрешность от Qt до Qmax</span>
            <span
              style={{ color: '#858585', width: '20%' }}
            >{`${viewData.details.q_max_limit}%`}</span>
          </Mobile.ContentRow>
        </Mobile.Paper>
      </Container>
    </React.Fragment>
  )
}

export default MetrologicalChars
