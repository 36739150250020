// ----------------------------------------------------------------
/* eslint-disable array-callback-return */
// ----------------------------------------------------------------
import React, { ReactElement, useState, useEffect, useRef, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Checkbox } from '@mui/material'
import Input from '../../../../ui/input/index.tsx'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import FilterListIcon from '@mui/icons-material/FilterList'
import Badge from '@mui/material/Badge'
import { CSSObject } from 'styled-components'

import { setOpen as setOpenRightContainer } from '../../../../store/slices/app/comps/rightContainer.js'

interface ITableMobileProps {
  data: Array<any[]>
  css: CSSObject
  rowCallback: Function
  rowCallbackParams: string
  rowSelectionCallback: Function
  actionsConroller: ReactElement
}

const TableMobile = (props: ITableMobileProps): ReactElement => {
  const {
    data,
    css,
    rowCallback = () => {},
    actionsConroller = <span style={{ color: 'gray', letterSpacing: '1.2px' }}>[ действия ]</span>,
  } = props

  const dispatch = useDispatch()

  const [items, setItems] = useState(
    data.map(item => ({
      id: item[0] && item[0][1] ? item[0][1] : '',
      selected: false,
    })),
  )

  const [scrollCount, setScrollCount] = useState<number>(0)
  const [isFixed, setIsFixed] = useState<boolean>(false)
  const [seacrhString, setSearchString] = useState<string>('')

  const lastTouchY = useRef(0)

  const withSearchStringData = useMemo(() => {
    return data.filter(item => {
      let success = false

      item.forEach((deepItem, index) => {
        if (index > 1) {
          if (typeof deepItem[1] === 'string' || typeof deepItem[1] === 'number') {
            if (~deepItem[1].toString().toLocaleLowerCase().indexOf(seacrhString.toLowerCase())) {
              success = true
            }
          }
        }
      })

      if (success) {
        return item
      }
    })
  }, [data, seacrhString])

  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      if (event.deltaY > 0) {
        setScrollCount(prev => prev + 1)
      } else if (event.deltaY < 0) {
        setScrollCount(prev => (prev > 0 ? prev - 1 : 0))
      }
    }

    const handleTouchMove = (event: TouchEvent) => {
      const touch = event.touches[0]
      const deltaY = touch.clientY - lastTouchY.current
      lastTouchY.current = touch.clientY
      if (deltaY > 0) {
        setScrollCount(prev => (prev > 0 ? prev - 1 : 0))
      } else if (deltaY < 0) {
        setScrollCount(prev => prev + 1)
      }
    }

    window.addEventListener('wheel', handleWheel)
    window.addEventListener('touchmove', handleTouchMove)

    return () => {
      window.removeEventListener('wheel', handleWheel)
      window.removeEventListener('touchmove', handleTouchMove)
    }
  }, [])

  useEffect(() => {
    if (scrollCount > 8) {
      setIsFixed(true)
    } else if (scrollCount <= 2) {
      setIsFixed(false)
    }
  }, [scrollCount])

  const handleSelectAll = () => {
    const allSelected = items.every(item => item.selected)
    setItems(
      items.map(item => ({
        ...item,
        selected: !allSelected,
      })),
    )
  }

  const handleCheckboxChange = (currentId: string) => {
    setItems(prevItems =>
      prevItems.map(item => (item.id === currentId ? { ...item, selected: !item.selected } : item)),
    )
  }

  const HandleSearchString = (param: string) => {
    setSearchString(param)
  }

  return (
    <React.Fragment>
      <section
        style={{
          ...css,
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexFlow: 'row',
            position: isFixed ? 'fixed' : 'relative',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            gap: '10px',
            padding: '8px 8px',
            paddingRight: '20px',
            marginBottom: '12px',
            backgroundColor: isFixed ? '#f8f9fc' : 'white',
            boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.05)',
            border: '1px solid transparent',
            zIndex: 2,
            top: isFixed ? '61.11px' : 'auto',
            left: 0,
            borderRadius: isFixed ? '0px' : '12px',

            ...(isFixed && {
              borderBottomRightRadius: '12px',
              borderBottomLeftRadius: '12px',
            }),
          }}
        >
          <Input
            value={null}
            placeholder={'Поиск по списку'}
            notRequired={true}
            isSearch={true}
            actions={{
              change: HandleSearchString,
              keyUp: undefined,
            }}
          />
          <Badge
            badgeContent={4}
            color='primary'
            sx={{
              '& .MuiBadge-badge': {
                fontSize: '11px',
              },
            }}
          >
            <FilterListIcon
              onClick={() => {
                dispatch(setOpenRightContainer(true))
              }}
              color='action'
            />
          </Badge>
        </div>

        <div
          style={{
            display: 'flex',
            flexFlow: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            position: 'relative',
            width: '100%',
            padding: '8px 0px',
            paddingRight: '20px',
            marginBottom: '13px',
            backgroundColor: 'white',
            borderRadius: '12px',
            boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.05)',
            border: '1px solid transparent',
          }}
        >
          <div style={{ display: 'flex', flexFlow: 'row', alignItems: 'center', width: '30%' }}>
            <Checkbox checked={items.every(item => item.selected)} onChange={handleSelectAll} />
            <span style={{ color: 'gray', letterSpacing: '1.2px' }}>
              {items.filter(item => item.selected === true).length > 0
                ? `${items.filter(item => item.selected === true).length}`
                : ''}
            </span>
          </div>
          {items.filter(item => item.selected === true).length > 0 && (
            <div
              style={{
                display: 'flex',
                flexFlow: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
                width: '70%',
              }}
            >
              {actionsConroller}
            </div>
          )}
        </div>

        {withSearchStringData.map((item: Array<any>, index: number): ReactElement => {
          const currentId = item[0] && item[0][1] ? item[0][1] : ''

          return (
            <div
              key={index}
              style={{
                display: 'block',
                position: 'relative',
                width: '100%',
                border: '1px solid #d7ddea',
                backgroundColor: 'white',
                minHeight: '40px',
                borderRadius: '12px',
                marginTop: index > 0 ? '14px' : '0px',
                padding: '0px 0px 50px',
                overflow: 'hidden',
              }}
            >
              <div
                style={{
                  display: 'block',
                  position: 'relative',
                  width: '100%',
                  minHeight: '30px',
                  backgroundColor: '#EAEDF3',
                  marginBottom: '10px',
                }}
              >
                <Checkbox
                  checked={items.find(item => item.id === currentId)?.selected || false}
                  onChange={() => handleCheckboxChange(currentId)}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexFlow: 'row',
                  alignItems: 'center',
                  position: 'absolute',
                  top: '100%',
                  left: '100%',
                  marginLeft: '-160px',
                  marginTop: '-48px',
                  gap: '8px',
                  cursor: 'pointer',
                }}
              >
                <span
                  onClick={() => {
                    rowCallback(currentId)
                  }}
                  style={{
                    color: 'rgb(0, 132, 226)',
                    fontSize: '16px',
                    fontWeight: 500,
                    letterSpacing: '1px',
                  }}
                >
                  Подробнее
                </span>
                <ArrowForwardIcon
                  style={{
                    color: 'rgb(0, 132, 226)',
                    marginTop: '3px',
                  }}
                />
              </div>

              {item.map((contentLine, index) => {
                if (index > 1) {
                  return (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        flexFlow: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        marginBottom: '20px',
                        padding: '0px 12px',
                      }}
                    >
                      <span
                        style={{
                          display: 'block',
                          position: 'relative',
                          fontWeight: 600,
                          width: '50%',
                          lineHeight: '24px',
                        }}
                      >
                        {contentLine[0]}
                      </span>
                      {typeof contentLine[1] === 'string' ? (
                        <span style={{ textAlign: 'right', lineHeight: '24px', flexGrow: 2 }}>
                          {contentLine[1]}
                        </span>
                      ) : (
                        <React.Fragment>{contentLine[1]}</React.Fragment>
                      )}
                    </div>
                  )
                }
                return null
              })}
            </div>
          )
        })}
      </section>
    </React.Fragment>
  )
}

export default TableMobile
