import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import css from '../../style.css'
import AccordionComponent from '../../../../../LocalElements/Accordion/Accordion'

import { IModelProtocolMi1592MobileShow } from '../../../../../../types'

const { Container, Mobile } = css

const RelativeError: React.FC = (): ReactElement => {
  const viewData: IModelProtocolMi1592MobileShow = useSelector(
    (state: any) => state.showProtocolReducer.data,
  )

  return (
    <React.Fragment>
      <Container>
        <Mobile.Paper style={{ padding: 0 }}>
          <AccordionComponent
            title={
              <div
                style={{
                  display: 'flex',
                  flexFlow: 'row',
                  justifyContent: 'space-between',
                  gap: '50px',
                }}
              >
                <h3 style={{ width: '40px' }}>Qmin</h3>
                <span
                  style={{
                    backgroundColor:
                      +viewData.protocolIndication[0].permissible_error_1 <
                        +viewData.details.q_min_limit &&
                      +viewData.protocolIndication[0].permissible_error_2 <
                        +viewData.details.q_min_limit &&
                      +viewData.protocolIndication[0].permissible_error_3 <
                        +viewData.details.q_min_limit
                        ? '#E5F3FC'
                        : '#FFEBED',
                    color:
                      +viewData.protocolIndication[0].permissible_error_1 <
                        +viewData.details.q_min_limit &&
                      +viewData.protocolIndication[0].permissible_error_2 <
                        +viewData.details.q_min_limit &&
                      +viewData.protocolIndication[0].permissible_error_3 <
                        +viewData.details.q_min_limit
                        ? '#0589E7'
                        : '#FF5963',
                    fontWeight: 400,
                    padding: '4px 13px 5px',
                    borderRadius: '8px',
                    marginTop: '-3px',
                  }}
                >
                  {+viewData.protocolIndication[0].permissible_error_1 <
                    +viewData.details.q_min_limit &&
                  +viewData.protocolIndication[0].permissible_error_2 <
                    +viewData.details.q_min_limit &&
                  +viewData.protocolIndication[0].permissible_error_3 <
                    +viewData.details.q_min_limit
                    ? 'Значения в норме'
                    : 'За пределами нормы'}
                </span>
              </div>
            }
            titleSize={'17px'}
            titleMt={'4px'}
            iconSize={'large'}
            backgroundColor={'white'}
            padding={'0px 0px'}
            plSum={'8px'}
            prSum={'8px'}
            plDet={'8px'}
            prDet={'8px'}
            bblr={'12px'}
            bbrr={'12px'}
            dataType={'custom'}
            data={
              <React.Fragment>
                <div style={{ display: 'flex', flexFlow: 'column', gap: '10px' }}>
                  <Mobile.ContentRow>
                    <h3>Первый замер</h3>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Расход во время измерения</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[0].q_i_1}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Показания на начало</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[0].start_1}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Показания на конец</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[0].finish_1}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Объем по эталону</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[0].v_u_i_1}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Объем по показаниям СИ</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[0].v_y_i_1}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Относительная погрешность</span>
                    <span
                      style={{
                        color:
                          +viewData.protocolIndication[0].permissible_error_1 <
                          +viewData.details.q_min_limit
                            ? '#0589E7'
                            : '#FF5963',
                        width: '30%',
                      }}
                    >
                      {`${viewData.protocolIndication[0].permissible_error_1}`} %
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Допускаемая погрешность</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.details.q_min_limit}`} %
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'space-around' }}>
                    <span
                      style={{
                        width: '90%',
                        height: '1px',
                        backgroundColor: 'gray',
                        opacity: 0.2,
                        borderRadius: '1px',
                        marginTop: '6px',
                      }}
                    />
                  </Mobile.ContentRow>
                  <Mobile.ContentRow>
                    <h3>Второй замер</h3>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Расход во время измерения</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[0].q_i_2}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Показания на начало</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[0].start_2}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Показания на конец</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[0].finish_2}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Объем по эталону</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[0].v_u_i_2}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Объем по показаниям СИ</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[0].v_y_i_2}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Относительная погрешность</span>
                    <span
                      style={{
                        color:
                          +viewData.protocolIndication[0].permissible_error_2 <
                          +viewData.details.q_min_limit
                            ? '#0589E7'
                            : '#FF5963',
                        width: '30%',
                      }}
                    >
                      {`${viewData.protocolIndication[0].permissible_error_2}`} %
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Допускаемая погрешность</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.details.q_min_limit}`} %
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'space-around' }}>
                    <span
                      style={{
                        width: '90%',
                        height: '1px',
                        backgroundColor: 'gray',
                        opacity: 0.2,
                        borderRadius: '1px',
                        marginTop: '6px',
                      }}
                    />
                  </Mobile.ContentRow>
                  <Mobile.ContentRow>
                    <h3>Третий замер</h3>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Расход во время измерения</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[0].q_i_3}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Показания на начало</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[0].start_3}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Показания на конец</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[0].finish_3}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Объем по эталону</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[0].v_u_i_3}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Объем по показаниям СИ</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[0].v_y_i_3}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Относительная погрешность</span>
                    <span
                      style={{
                        color:
                          +viewData.protocolIndication[0].permissible_error_3 <
                          +viewData.details.q_min_limit
                            ? '#0589E7'
                            : '#FF5963',
                        width: '30%',
                      }}
                    >
                      {`${viewData.protocolIndication[0].permissible_error_3}`} %
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Допускаемая погрешность</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.details.q_min_limit}`} %
                    </span>
                  </Mobile.ContentRow>
                </div>
              </React.Fragment>
            }
          />
        </Mobile.Paper>
      </Container>
      <Container>
        <Mobile.Paper style={{ padding: 0 }}>
          <AccordionComponent
            title={
              <div
                style={{
                  display: 'flex',
                  flexFlow: 'row',
                  justifyContent: 'space-between',
                  gap: '50px',
                }}
              >
                <h3 style={{ width: '40px' }}>Qt</h3>
                <span
                  style={{
                    backgroundColor:
                      +viewData.protocolIndication[1].permissible_error_1 <
                        +viewData.details.q_min_limit &&
                      +viewData.protocolIndication[1].permissible_error_2 <
                        +viewData.details.q_min_limit &&
                      +viewData.protocolIndication[1].permissible_error_3 <
                        +viewData.details.q_min_limit
                        ? '#E5F3FC'
                        : '#FFEBED',
                    color:
                      +viewData.protocolIndication[1].permissible_error_1 <
                        +viewData.details.q_min_limit &&
                      +viewData.protocolIndication[1].permissible_error_2 <
                        +viewData.details.q_min_limit &&
                      +viewData.protocolIndication[1].permissible_error_3 <
                        +viewData.details.q_min_limit
                        ? '#0589E7'
                        : '#FF5963',
                    fontWeight: 400,
                    padding: '4px 13px 5px',
                    borderRadius: '8px',
                    marginTop: '-3px',
                  }}
                >
                  {+viewData.protocolIndication[1].permissible_error_1 <
                    +viewData.details.q_min_limit &&
                  +viewData.protocolIndication[1].permissible_error_2 <
                    +viewData.details.q_min_limit &&
                  +viewData.protocolIndication[1].permissible_error_3 <
                    +viewData.details.q_min_limit
                    ? 'Значения в норме'
                    : 'За пределами нормы'}
                </span>
              </div>
            }
            titleSize={'17px'}
            titleMt={'4px'}
            iconSize={'large'}
            backgroundColor={'white'}
            padding={'0px 0px'}
            plSum={'8px'}
            prSum={'8px'}
            plDet={'8px'}
            prDet={'8px'}
            bblr={'12px'}
            bbrr={'12px'}
            dataType={'custom'}
            data={
              <React.Fragment>
                <div style={{ display: 'flex', flexFlow: 'column', gap: '10px' }}>
                  <Mobile.ContentRow>
                    <h3>Первый замер</h3>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Расход во время измерения</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[1].q_i_1}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Показания на начало</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[1].start_1}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Показания на конец</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[1].finish_1}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Объем по эталону</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[1].v_u_i_1}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Объем по показаниям СИ</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[1].v_y_i_1}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Относительная погрешность</span>
                    <span
                      style={{
                        color:
                          +viewData.protocolIndication[1].permissible_error_1 <
                          +viewData.details.q_min_limit
                            ? '#0589E7'
                            : '#FF5963',
                        width: '30%',
                      }}
                    >
                      {`${viewData.protocolIndication[1].permissible_error_1}`} %
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Допускаемая погрешность</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.details.q_min_limit}`} %
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'space-around' }}>
                    <span
                      style={{
                        width: '90%',
                        height: '1px',
                        backgroundColor: 'gray',
                        opacity: 0.2,
                        borderRadius: '1px',
                        marginTop: '6px',
                      }}
                    />
                  </Mobile.ContentRow>
                  <Mobile.ContentRow>
                    <h3>Второй замер</h3>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Расход во время измерения</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[1].q_i_2}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Показания на начало</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[1].start_2}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Показания на конец</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[1].finish_2}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Объем по эталону</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[1].v_u_i_2}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Объем по показаниям СИ</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[1].v_y_i_2}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Относительная погрешность</span>
                    <span
                      style={{
                        color:
                          +viewData.protocolIndication[1].permissible_error_2 <
                          +viewData.details.q_min_limit
                            ? '#0589E7'
                            : '#FF5963',
                        width: '30%',
                      }}
                    >
                      {`${viewData.protocolIndication[1].permissible_error_2}`} %
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Допускаемая погрешность</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.details.q_min_limit}`} %
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'space-around' }}>
                    <span
                      style={{
                        width: '90%',
                        height: '1px',
                        backgroundColor: 'gray',
                        opacity: 0.2,
                        borderRadius: '1px',
                        marginTop: '6px',
                      }}
                    />
                  </Mobile.ContentRow>
                  <Mobile.ContentRow>
                    <h3>Третий замер</h3>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Расход во время измерения</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[1].q_i_3}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Показания на начало</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[1].start_3}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Показания на конец</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[1].finish_3}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Объем по эталону</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[1].v_u_i_3}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Объем по показаниям СИ</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[1].v_y_i_3}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Относительная погрешность</span>
                    <span
                      style={{
                        color:
                          +viewData.protocolIndication[1].permissible_error_3 <
                          +viewData.details.q_min_limit
                            ? '#0589E7'
                            : '#FF5963',
                        width: '30%',
                      }}
                    >
                      {`${viewData.protocolIndication[1].permissible_error_3}`} %
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Допускаемая погрешность</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.details.q_min_limit}`} %
                    </span>
                  </Mobile.ContentRow>
                </div>
              </React.Fragment>
            }
          />
        </Mobile.Paper>
      </Container>
      <Container>
        <Mobile.Paper style={{ padding: 0 }}>
          <AccordionComponent
            title={
              <div
                style={{
                  display: 'flex',
                  flexFlow: 'row',
                  justifyContent: 'space-between',
                  gap: '50px',
                }}
              >
                <h3 style={{ width: '40px' }}>Qmax</h3>
                <span
                  style={{
                    backgroundColor:
                      +viewData.protocolIndication[2].permissible_error_1 <
                        +viewData.details.q_max_limit &&
                      +viewData.protocolIndication[2].permissible_error_2 <
                        +viewData.details.q_max_limit &&
                      +viewData.protocolIndication[2].permissible_error_3 <
                        +viewData.details.q_max_limit
                        ? '#E5F3FC'
                        : '#FFEBED',
                    color:
                      +viewData.protocolIndication[2].permissible_error_1 <
                        +viewData.details.q_max_limit &&
                      +viewData.protocolIndication[2].permissible_error_2 <
                        +viewData.details.q_max_limit &&
                      +viewData.protocolIndication[2].permissible_error_3 <
                        +viewData.details.q_max_limit
                        ? '#0589E7'
                        : '#FF5963',
                    fontWeight: 400,
                    padding: '4px 13px 5px',
                    borderRadius: '8px',
                    marginTop: '-3px',
                  }}
                >
                  {+viewData.protocolIndication[2].permissible_error_1 <
                    +viewData.details.q_max_limit &&
                  +viewData.protocolIndication[2].permissible_error_2 <
                    +viewData.details.q_max_limit &&
                  +viewData.protocolIndication[2].permissible_error_3 <
                    +viewData.details.q_max_limit
                    ? 'Значения в норме'
                    : 'За пределами нормы'}
                </span>
              </div>
            }
            titleSize={'17px'}
            titleMt={'4px'}
            iconSize={'large'}
            backgroundColor={'white'}
            padding={'0px 0px'}
            plSum={'8px'}
            prSum={'8px'}
            plDet={'8px'}
            prDet={'8px'}
            bblr={'12px'}
            bbrr={'12px'}
            dataType={'custom'}
            data={
              <React.Fragment>
                <div style={{ display: 'flex', flexFlow: 'column', gap: '10px' }}>
                  <Mobile.ContentRow>
                    <h3>Первый замер</h3>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Расход во время измерения</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[2].q_i_1}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Показания на начало</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[2].start_1}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Показания на конец</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[2].finish_1}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Объем по эталону</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[2].v_u_i_1}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Объем по показаниям СИ</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[2].v_y_i_1}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Относительная погрешность</span>
                    <span
                      style={{
                        color:
                          +viewData.protocolIndication[2].permissible_error_1 <
                          +viewData.details.q_max_limit
                            ? '#0589E7'
                            : '#FF5963',
                        width: '30%',
                      }}
                    >
                      {`${viewData.protocolIndication[2].permissible_error_1}`} %
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Допускаемая погрешность</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.details.q_max_limit}`} %
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'space-around' }}>
                    <span
                      style={{
                        width: '90%',
                        height: '1px',
                        backgroundColor: 'gray',
                        opacity: 0.2,
                        borderRadius: '1px',
                        marginTop: '6px',
                      }}
                    />
                  </Mobile.ContentRow>
                  <Mobile.ContentRow>
                    <h3>Второй замер</h3>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Расход во время измерения</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[2].q_i_2}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Показания на начало</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[2].start_2}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Показания на конец</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[2].finish_2}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Объем по эталону</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[2].v_u_i_2}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Объем по показаниям СИ</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[2].v_y_i_2}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Относительная погрешность</span>
                    <span
                      style={{
                        color:
                          +viewData.protocolIndication[2].permissible_error_2 <
                          +viewData.details.q_max_limit
                            ? '#0589E7'
                            : '#FF5963',
                        width: '30%',
                      }}
                    >
                      {`${viewData.protocolIndication[2].permissible_error_2}`} %
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Допускаемая погрешность</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.details.q_max_limit}`} %
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'space-around' }}>
                    <span
                      style={{
                        width: '90%',
                        height: '1px',
                        backgroundColor: 'gray',
                        opacity: 0.2,
                        borderRadius: '1px',
                        marginTop: '6px',
                      }}
                    />
                  </Mobile.ContentRow>
                  <Mobile.ContentRow>
                    <h3>Третий замер</h3>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Расход во время измерения</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[2].q_i_3}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Показания на начало</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[2].start_3}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Показания на конец</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[2].finish_3}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Объем по эталону</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[2].v_u_i_3}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Объем по показаниям СИ</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.protocolIndication[2].v_y_i_3}`} м3
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Относительная погрешность</span>
                    <span
                      style={{
                        color:
                          +viewData.protocolIndication[2].permissible_error_3 <
                          +viewData.details.q_max_limit
                            ? '#0589E7'
                            : '#FF5963',
                        width: '30%',
                      }}
                    >
                      {`${viewData.protocolIndication[2].permissible_error_3}`} %
                    </span>
                  </Mobile.ContentRow>
                  <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
                    <span style={{ width: '70%' }}>Допускаемая погрешность</span>
                    <span style={{ color: '#858585', width: '30%' }}>
                      {`${viewData.details.q_max_limit}`} %
                    </span>
                  </Mobile.ContentRow>
                </div>
              </React.Fragment>
            }
          />
        </Mobile.Paper>
      </Container>
    </React.Fragment>
  )
}

export default RelativeError
